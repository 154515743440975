import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from '../components/footer';
import Header from '../components/header';
import Container from '../components/container';
import '../styles/tailwind.scss';
import '../styles/team.scss';
import { GatsbyImage } from "gatsby-plugin-image";
const Team = () => {
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { bio: { ne: null } } }
    ) {
      nodes {
        frontmatter {
          name
          title
          bio
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 800)
            }
          }
          linkedin
        }
        html
      }
    }
  }
  `);

  const teamMembers = data.allMarkdownRemark.nodes;
  console.log(data)

  function getImageData({ image, ...props }) {
    console.log(image)
    return getImageData({
      baseUrl: image.url,
      sourceWidth: image.width,
      sourceHeight: image.height,
      urlBuilder,
      pluginName: "gatsby-source-example",
      // If your host supports auto-format/content negotiation, pass this as the formats array
      formats: ["auto"],
      ...props,
    })
  }



  return (
    <Container>
      <Header />
      <div className='team'>
        <h1 className='fs-primary-heading'>Meet the Team</h1>
        <div className='team-cards'>
          {teamMembers.map((member, i) => (
            <div className="m-10 max-w-sm" key={`${member + i}`}>
              <div className="rounded-lg border bg-white px-4 pt-8 pb-10 shadow-lg h-full flex flex-col">
                <div className="relative mx-auto w-36 h-36 rounded-full">
                  <span className="absolute right-0 m-3 h-3 w-3 rounded-full bg-green-500 ring-2 ring-green-300 ring-offset-2"></span>
                  <GatsbyImage image={member.frontmatter.image.childImageSharp.gatsbyImageData} alt={member.frontmatter.name} className="mx-auto h-auto w-full rounded-full object-cover h-full" />

                </div>
                <h1 className="my-1 text-center text-xl font-bold leading-8 text-gray-900">{member.frontmatter.name}</h1>
                <h3 className="font-lg text-semibold text-center leading-6 text-gray-600 mb-5">{member.frontmatter.title}</h3>
                <p className="text-center text-sm leading-6 text-gray-500 hover:text-gray-600 pt-1 pb-1">{member.frontmatter.bio}</p>
                <ul style={{ marginTop: 'auto' }} className="mt-3 divide-y rounded bg-gray-100 py-2 px-3 text-gray-600 shadow-sm hover:text-gray-700 hover:shadow mt-auto">
                  <li className="flex items-center py-3 text-sm">
                    <li><a href={member.frontmatter.linkedin}><i className="fab fa-linkedin"></i></a></li>
                    <span className="ml-auto">
                      <span className="rounded-full bg-green-200 py-1 px-2 text-xs font-medium text-green-700"><a href={member.frontmatter.linkedin}>Linkedin</a></span>
                    </span>
                  </li>

                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Container>

  );
};

export default Team;
